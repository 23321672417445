import React from "react";
import ReactResizeDetector from "react-resize-detector";
import { scroller, Link } from "react-scroll";
import {
  Button,
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink
} from "reactstrap";

import NumberUtils from "../utils/NumberUtils";
import Utils from "../utils/Utils";

const DEFAULT_NAV_BG_COLOR = "#A6002A";
const DEFAULT_NAV_FG_COLOR = "#FFFFFF";
const WIDTH_BREAKPOINT = 768; // md

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      dark: true
    };

    this.setColors();
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  setColors = () => {
    if (window.innerWidth < WIDTH_BREAKPOINT) {
      this.setState({
        navBGColor: DEFAULT_NAV_BG_COLOR,
        navFGColor: DEFAULT_NAV_FG_COLOR,
        dark: true
      });
      return;
    }

    if (Utils.isDataEmpty(this.navRef) || this.navRef.clientHeight <= 0) {
      return;
    }

    let interpolate = NumberUtils.Interpolate(
      0,
      255,
      this.navRef.clientHeight,
      Utils.clamp(window.pageYOffset, 0, this.navRef.clientHeight)
    );

    let opacity = NumberUtils.ToHex(interpolate);

    let foregroundColor =
      NumberUtils.ToHex(interpolate) +
      NumberUtils.ToHex(interpolate) +
      NumberUtils.ToHex(interpolate);

    this.setState({
      navBGColor: DEFAULT_NAV_BG_COLOR + opacity,
      navFGColor: foregroundColor,
      dark: interpolate > 0
    });
  };

  onScroll = () => {
    this.setColors();
  };

  render() {
    return (
      <div ref={ref => (this.navRef = ref)} className="p-nav">
        <ReactResizeDetector
          handleWidth
          handleHeight
          onResize={this.setColors}
        />
        <Navbar
          dark={this.state.dark}
          light={!this.state.dark}
          expand="md"
          style={{
            padding: "1em 0.5em",
            backgroundColor: this.state.navBGColor
          }}
        >
          <Link
            activeClass="active"
            className="test1"
            spy={true}
            smooth={true}
            duration={500}
            to="container"
          >
            <NavbarBrand href="#" className="pl-2">
              PaperGrin
            </NavbarBrand>
          </Link>
          <NavbarToggler onClick={this.toggle} dark />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem className="px-3">
                <Link spy={true} smooth={true} duration={500} to="product">
                  <NavLink href="#product">Product</NavLink>
                </Link>
              </NavItem>
              <NavItem className="px-3">
                <Link spy={true} smooth={true} duration={500} to="description">
                  <NavLink href="#description">How it works</NavLink>
                </Link>
              </NavItem>
              <NavItem className="px-3">
                <Link spy={true} smooth={true} duration={500} to="gallery">
                  <NavLink href="#gallery">Gallery</NavLink>
                </Link>
              </NavItem>
              <NavItem className="px-3">
                <Link spy={true} smooth={true} duration={500} to="pricing">
                  <NavLink href="#pricing">Pricing</NavLink>
                </Link>
              </NavItem>
              <Button
                color="p-light"
                className="text-uppercase nav-button"
                onClick={() => {
                  scroller.scrollTo("signup", {
                    smooth: "easeInOutQuart",
                    duration: 500
                  });
                }}
              >
                Get started
              </Button>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

export default Header;
