import { API } from "aws-amplify";

import AWSUtils from "../utils/AWSUtils";

async function contact(data: Object) {
  let apiName = AWSUtils.API_NAME;
  let path = "/contact";

  let request = {
    body: {
      ...data
    }
  };
  return await API.post(apiName, path, request);
}

export default {
  contact
};
