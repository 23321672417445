// Utils for AWS APIs

const BACKEND_STAGES = {
  DEVELOPMENT: { value: 0, name: "Development" },
  PRODUCTION: { value: 1, name: "Production" }
};

// Controls whether prod or dev backend is running
// TODO: change this to switch between stages
const CURRENT_STAGE = BACKEND_STAGES.PRODUCTION;

const DEVELOPMENT_BACKEND = "dev-pg-backend-resources";
const PRODUCTION_BACKEND = "prod-pg-backend-resources";

const AWS_URIS = {
  API_NAME:
    CURRENT_STAGE === BACKEND_STAGES.PRODUCTION
      ? PRODUCTION_BACKEND
      : DEVELOPMENT_BACKEND
};

/* Exports ================================================================== */

export default {
  BACKEND_STAGES,
  CURRENT_STAGE,
  ...AWS_URIS
};
