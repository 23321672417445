// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
  aws_app_analytics: "enable",
  aws_cloud_logic: "enable",
  aws_cloud_logic_custom: [
    {
      id: "pqttnscn73",
      name: "prod-pg-backend-resources",
      description: "",
      endpoint: "https://pqttnscn73.execute-api.us-west-1.amazonaws.com/prod",
      region: "us-west-1",
      paths: ["/contact"]
    }
  ],
  aws_cognito_identity_pool_id:
    "us-west-2:81ef978e-38f3-49c1-8915-a4fb01353034",
  aws_cognito_region: "us-west-2",
  aws_mobile_analytics_app_id: "3fc58f0bc202477582076ea3489e7518",
  aws_mobile_analytics_app_region: "us-east-1",
  aws_project_id: "369e9435-b9cc-4bb9-9cf9-d21d2f236fea",
  aws_project_name: "Website",
  aws_project_region: "us-west-1",
  aws_resource_name_prefix: "website-mobilehub-2067847553"
};

export default awsmobile;
