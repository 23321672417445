import classNames from "classnames";
import React from "react";
import { FiCheckCircle, FiXCircle } from "react-icons/fi";
import { MdEdit, MdGroup, MdPoll } from "react-icons/md";
import { scroller } from "react-scroll";
import Slider from "react-slick";
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row
} from "reactstrap";

import APIContact from "../api/APIContact";
import Demo1 from "../assets/images/demo_1.jpg";
import Demo2 from "../assets/images/demo_2.jpg";
import Demo3 from "../assets/images/demo_3.jpg";
import Gallery1 from "../assets/images/gallery_1.png";
import Gallery2 from "../assets/images/gallery_2.png";
import Gallery3 from "../assets/images/gallery_3.png";
import IPhoneWhite from "../assets/images/iphone_whitedash_export.png";
import MacWhite from "../assets/images/macbook_whitechart.png";
import User1 from "../assets/images/user_1.png";
import User2 from "../assets/images/user_2.png";
import User3 from "../assets/images/user_3.png";
import HexBR from "../assets/polygons/hex_br.svg";
import HexMTRMulti from "../assets/polygons/hex_mtr_multi.svg";
import HexTR from "../assets/polygons/hex_tr.svg";
import PolyBMLMulti from "../assets/polygons/poly_bml_multi.svg";
import PolyTMLMulti from "../assets/polygons/poly_tml_multi.svg";
import RecGrey from "../assets/polygons/rec_grey.svg";
import RecMR from "../assets/polygons/rec_mr.svg";
import RecMTL from "../assets/polygons/rec_mtl.svg";
import Errors from "../constants/Errors";
import Regex from "../constants/Regex";
import Utils from "../utils/Utils";
import Header from "./Header";

const ICON_SIZE = "5em";
const FIELDS_REQUIRED = {
  email: true,
  name: true,
  company: false
};

class HomePage extends React.Component {
  state = {
    name: "",
    email: "",
    company: "",
    nameError: "",
    emailError: "",
    companyError: "",
    // Submit button
    error: "",
    sendingAlertVisible: false,
    errorAlertVisible: false,
    successAlertVisible: false
  };

  ////////////////////////////////////////////////
  ///////////////// VALIDATIONS //////////////////
  ////////////////////////////////////////////////

  // Null indicates that the field is valid.
  _validateEmailField() {
    if (Utils.isDataEmpty(this.state.email)) {
      return Errors.EMPTY_FIELD_ERROR;
    } else if (!Regex.EMAIL_VALIDATION_REGEX.test(this.state.email)) {
      return Errors.INVALID_EMAIL_ERROR;
    } else {
      return null;
    }
  }

  // Null indicates that the field is valid.
  _validateTextField(fieldName: String) {
    let fieldValue = this.state[fieldName];
    if (!FIELDS_REQUIRED[fieldName]) {
      return null;
    } else if (Utils.isDataEmpty(fieldValue)) {
      return Errors.EMPTY_FIELD_ERROR;
    } else {
      return null;
    }
  }

  _validateFormFieldsAndGetErrors() {
    let errors = {};

    let emailError = this._validateEmailField();
    if (!Utils.isNull(emailError)) {
      errors.emailError = emailError;
    }

    ["name", "company"].forEach(fieldName => {
      let fieldError = this._validateTextField(fieldName);
      if (!Utils.isNull(fieldError)) {
        errors[fieldName + "Error"] = fieldError;
      }
    });

    return errors;
  }

  ////////////////////////////////////////////////
  //////////////// STATE UPDATES /////////////////
  ////////////////////////////////////////////////

  onChangeSignUpField = (key, value) => {
    this.setState({
      [key]: value
    });
  };

  onDismissSendingAlert = () => {
    this.setState({ sendingAlertVisible: false });
  };

  onDismissErrorAlert = () => {
    this.setState({ errorAlertVisible: false });
  };

  onDismissSuccessAlert = () => {
    this.setState({ successAlertVisible: false });
  };

  onSubmit = async (event: Object) => {
    event.preventDefault();

    let errors = this._validateFormFieldsAndGetErrors();
    if (!Utils.isDataEmpty(errors)) {
      // Update state with the errors
      this.setState({
        ...errors
      });
      return;
    }

    this.setState({ sendingAlertVisible: true });

    APIContact.contact({
      name: this.state.name,
      email: this.state.email,
      company: this.state.company
    })
      .then(() => {
        this.setState({
          successAlertVisible: true,
          sendingAlertVisible: false
        });
      })
      .catch(err => {
        console.log("Couldn't send the email due to error: ", err);
        this.setState({
          successAlertVisible: true,
          sendingAlertVisible: false,
          error:
            "Couldn't send the email. Make sure you've filled out the required fields, and try again in sometime."
        });
      });
  };

  ////////////////////////////////////////////////
  //////////////////// RENDER ////////////////////
  ////////////////////////////////////////////////

  static _userInfo(image: Object, name: String, location: String) {
    return (
      <Container
        fluid
        className={classNames("flex-fill", "d-flex", "align-items-center")}
        style={{ position: "relative" }}
      >
        <Row className={classNames("flex-fill", "flex-row")}>
          <Col
            className={classNames("col-4", "flex-column")}
            style={{ textAlign: "end" }}
          >
            <img
              src={image}
              alt={""}
              style={{ width: "3em", borderRadius: "50%" }}
            />
          </Col>
          <Col
            className={classNames("col-8", "flex-column", "align-items-start")}
          >
            <h5
              className="subheading"
              style={{ fontSize: "1em", textAlign: "start" }}
            >
              {name}
            </h5>
            <h5
              className="subheading"
              style={{ fontSize: "1em", textAlign: "start" }}
            >
              {location}
            </h5>
          </Col>
        </Row>
      </Container>
    );
  }

  static _firstPage() {
    return (
      <Row
        className={classNames("flex-fill", "intro", "dynamic-row")}
        style={{ minHeight: "80vh" }}
        id="introduction"
      >
        <Col>
          <div
            className={classNames(
              "d-flex",
              "flex-column",
              "justify-content-center",
              "align-items-start"
            )}
            style={{ height: "100%" }}
          >
            <h1>Data Intensive Storytelling, quick and easy</h1>
            <p className="subheading">
              Create compelling presentations in a scroll format with data
              visualizations, rich media and animations.
            </p>
            <p className="subheading">
              Publish, Share and Analyze reader activity.
            </p>
            <Button
              color="p-red"
              className={classNames("text-uppercase", "px-4", "py-2", "mr-5")}
              onClick={() => {
                scroller.scrollTo("signup", {
                  smooth: "easeInOutQuart",
                  duration: 500
                });
              }}
            >
              Get started
            </Button>
          </div>
        </Col>
        <Col className="mx-2 my-4">
          <div
            className={classNames(
              "d-flex",
              "flex-column",
              "justify-content-center",
              "align-items-end"
            )}
            style={{ height: "100%" }}
          >
            <img
              src={MacWhite}
              alt={"Light theme dashboard"}
              style={{
                width: "100%",
                maxWidth: "691px",
                minWidth: "100px",
                zIndex: 1
              }}
            />
            <img
              src={IPhoneWhite}
              alt={"Light theme dashboard"}
              style={{
                width: "35%",
                maxWidth: "205px",
                minWidth: "50px",
                position: "absolute",
                marginTop: "auto",
                marginBottom: "auto",
                top: "20%",
                bottom: 0,
                right: 0,
                zIndex: 2
              }}
            />
          </div>
        </Col>
      </Row>
    );
  }

  static _secondPage() {
    return (
      <Row
        className={classNames(
          "flex-fill, flex-column",
          "align-items-end",
          "align-content-end",
          "justify-contents-end"
        )}
        style={{
          minHeight: "70vh",
          position: "relative",
          marginLeft: "0",
          marginRight: "0"
        }}
        id="product"
      >
        <Col style={{ minHeight: "12vh" }}>
          <img
            src={PolyTMLMulti}
            alt={""}
            style={{
              position: "absolute",
              marginTop: "2vh",
              top: 0,
              left: 0,
              zIndex: -1,
              minWidth: "90px",
              width: "12vw"
            }}
          />
        </Col>
        <Col>
          <h2 style={{ textAlign: "center" }}>Intuitive and Powerful</h2>
          <p className="subheading" style={{ textAlign: "center" }}>
            Create impressive scrolls in a few minutes, share, and improve them.
          </p>
        </Col>
        <Col
          style={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center"
          }}
          className="my-5"
        >
          <div
            style={{
              margin: "auto",
              width: "100%",
              maxWidth: "800px",
              minWidth: "100px",
              zIndex: 1,
              position: "relative"
            }}
          >
            <Slider
              dots={true}
              arrows={false}
              // className={"thin-shadow round-borders"}
            >
              <div className={"small-horizontal-padding"}>
                <img
                  style={{
                    maxWidth: "800px",
                    width: "100%"
                  }}
                  src={Gallery1}
                  alt="Gallery"
                  className={"thin-shadow round-borders"}
                />
              </div>
              <div className={"small-horizontal-padding"}>
                <img
                  style={{
                    maxWidth: "800px",
                    width: "100%"
                  }}
                  src={Gallery2}
                  alt="Gallery"
                  className={"thin-shadow round-borders"}
                />
              </div>
              <div className={"small-horizontal-padding"}>
                <img
                  style={{
                    maxWidth: "800px",
                    width: "100%"
                  }}
                  src={Gallery3}
                  alt="Gallery"
                  className={"thin-shadow round-borders"}
                />
              </div>
            </Slider>
            <img
              src={RecMTL}
              alt={""}
              style={{
                position: "absolute",
                top: "1vh",
                left: "-5vw",
                zIndex: -1,
                minWidth: "120px",
                width: "12vw"
              }}
            />
            <img
              src={RecMR}
              alt={""}
              style={{
                position: "absolute",
                bottom: "-5vh",
                right: "-3vw",
                zIndex: -1,
                minWidth: "120px",
                width: "12vw"
              }}
            />
          </div>
        </Col>
        <Col style={{ textAlign: "center" }} className="my-5">
          <Button
            color="p-red"
            className={classNames("text-uppercase", "px-4", "py-2")}
            onClick={() => {
              scroller.scrollTo("signup", {
                smooth: "easeInOutQuart",
                duration: 500
              });
            }}
          >
            Get started
          </Button>
        </Col>
      </Row>
    );
  }

  static _thirdPage() {
    return (
      <Row
        className={classNames(
          "flex-fill",
          "flex-column",
          "align-items-end",
          "align-content-end",
          "justify-contents-end",
          "mb-5"
        )}
        style={{
          minHeight: "70vh",
          position: "relative",
          marginLeft: "0",
          marginRight: "0"
        }}
        id="description"
      >
        <Col style={{ minHeight: "20vh" }}>
          <img
            src={HexMTRMulti}
            alt={""}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: -1,
              minWidth: "120px",
              width: "12vw"
            }}
          />
        </Col>
        <Col>
          <h2 style={{ textAlign: "center" }}>How PaperGrin works</h2>
          <p className="subheading" style={{ textAlign: "center" }}>
            A revolutionary new way to tell the world your data story.
          </p>
        </Col>
        <Col>
          <Container
            fluid
            className={classNames(
              "flex-fill",
              "d-flex",
              "dynamic-row",
              "justify-content-center",
              "align-items-center"
            )}
          >
            <Row className={classNames("col-md-3", "col-xs-12", "flex-column")}>
              <Col style={{ textAlign: "center" }} className="mt-5">
                <MdEdit size={ICON_SIZE} />
              </Col>
              <Col style={{ textAlign: "center" }} className="mt-4">
                <h2>Create data-rich scrolls</h2>
              </Col>
              <Col style={{ textAlign: "center" }} className="my-2">
                <p>
                  Use PaperGrin's rich collection of data visualization charts
                  (Waterfall, Gantt, Radar, Spider charts and more) or import
                  your own chart components, rich media and animations.
                </p>
              </Col>
            </Row>
            <Row className={classNames("col-md-3", "col-xs-12")}>
              <Col style={{ textAlign: "center" }} className="mt-5">
                <MdGroup size={ICON_SIZE} />
                <Col style={{ textAlign: "center" }} className="mt-4">
                  <h2>Create data-rich scrolls</h2>
                </Col>
                <Col style={{ textAlign: "center" }} className="my-2">
                  <p>
                    Use PaperGrin's rich collection of data visualization charts
                    (Waterfall, Gantt, Radar, Spider charts and more) or import
                    your own chart components, rich media and animations.
                  </p>
                </Col>
              </Col>
            </Row>
            <Row className={classNames("col-md-3", "col-xs-12")}>
              <Col style={{ textAlign: "center" }} className="mt-5">
                <MdPoll size={ICON_SIZE} />
                <Col style={{ textAlign: "center" }} className="mt-4">
                  <h2>Create data-rich scrolls</h2>
                </Col>
                <Col style={{ textAlign: "center" }} className="my-2">
                  <p>
                    Use PaperGrin's rich collection of data visualization charts
                    (Waterfall, Gantt, Radar, Spider charts and more) or import
                    your own chart components, rich media and animations.
                  </p>
                </Col>
              </Col>
            </Row>
          </Container>
        </Col>
        <div id="gallery" />
        <Col
          style={{
            position: "relative",
            minHeight: "2vh"
          }}
          className="my-5"
        >
          <img
            src={RecGrey}
            alt={""}
            style={{
              position: "absolute",
              top: 0,
              margin: "auto",
              left: 0,
              right: 0,
              zIndex: -1,
              minWidth: "120px",
              width: "75vw"
            }}
          />
        </Col>
        <Col
          style={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            position: "relative"
          }}
          className="mb-md-5 mb-1"
        >
          <h2>Look what our users published</h2>
          <p className="subheading" style={{ textAlign: "center" }}>
            Check out the scrolls created by our early private beta users.
          </p>
        </Col>
        <Col className="mt-md-5 mt-1">
          <Container
            fluid
            className={classNames(
              "flex-fill",
              "d-flex",
              "dynamic-row",
              "justify-content-center",
              "align-items-md-start",
              "align-items-center"
            )}
          >
            <Row className={classNames("col-md-3", "col-xs-12", "flex-column")}>
              <Col style={{ textAlign: "center" }} className="mt-5">
                <img
                  src={Demo1}
                  alt={""}
                  style={{
                    width: "70%"
                  }}
                />
              </Col>
              <Col style={{ textAlign: "center" }} className="mt-4">
                <h4>Unit Economics of Technology Unicorns: 2012 - 2019</h4>
              </Col>
              <Col style={{ textAlign: "center" }} className="my-2">
                {HomePage._userInfo(
                  User1,
                  "Susan Herrera",
                  "Sao Paolo, Brazil"
                )}
              </Col>
            </Row>
            <Row className={classNames("col-md-3", "col-xs-12", "flex-column")}>
              <Col style={{ textAlign: "center" }} className="mt-5">
                <img
                  src={Demo2}
                  alt={""}
                  style={{
                    width: "70%"
                  }}
                />
              </Col>
              <Col style={{ textAlign: "center" }} className="mt-4">
                <h4>Workplace Analytics Report - Latest</h4>
              </Col>
              <Col style={{ textAlign: "center" }} className="my-2">
                {HomePage._userInfo(
                  User2,
                  "Barbara Gordon",
                  "San Francisco, USA"
                )}
              </Col>
            </Row>
            <Row className={classNames("col-md-3", "col-xs-12", "flex-column")}>
              <Col style={{ textAlign: "center" }} className="mt-5">
                <img
                  src={Demo3}
                  alt={""}
                  style={{
                    width: "70%"
                  }}
                />
              </Col>
              <Col style={{ textAlign: "center" }} className="mt-4">
                <h4>Trends in Consumer Technology 2019</h4>
              </Col>
              <Col style={{ textAlign: "center" }} className="my-2">
                {HomePage._userInfo(User3, "Jared Friedman", "Toronto, Canada")}
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    );
  }

  static _fourthPage() {
    return (
      <Row
        className={classNames(
          "flex-fill",
          "flex-column",
          "align-items-end",
          "align-content-end",
          "justify-contents-end"
        )}
        style={{
          minHeight: "70vh",
          position: "relative",
          marginTop: "4em",
          marginLeft: "0",
          marginRight: "0"
        }}
        id="pricing"
      >
        <Col style={{ minHeight: "20vh" }}>
          <img
            src={PolyBMLMulti}
            alt={""}
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: -1,
              minWidth: "180px",
              width: "25vw"
            }}
          />
        </Col>
        <Col>
          <h2 style={{ textAlign: "center" }}>Pricing</h2>
          <p className="subheading" style={{ textAlign: "center" }}>
            Always free to publish!
          </p>
        </Col>
        <Col>
          <Container
            fluid
            className={classNames(
              "flex-fill",
              "d-flex",
              "dynamic-row",
              "justify-content-center",
              "align-items-md-start",
              "align-items-center",
              "col-md-7",
              "col-xs-12"
            )}
          >
            <Card
              className={classNames(
                "my-2",
                "mx-4",
                "thin-shadow",
                "col-md-4",
                "col-sm-6",
                "col-xs-10"
              )}
            >
              <CardBody>
                <CardTitle className={classNames("text-center", "my-3")}>
                  Free
                </CardTitle>
                <CardText>
                  <ul
                    style={{ listStyle: "none" }}
                    className={classNames("my-5", "text-secondary")}
                  >
                    <li>
                      <FiCheckCircle className="text-success" /> 5 scrolls per
                      month
                    </li>
                    <li>
                      <FiXCircle className="text-p-red" /> No private mode
                    </li>
                    <li>
                      <FiXCircle className="text-p-red" /> No document analytics
                    </li>
                    <li>
                      <FiCheckCircle className="text-success" /> One-click
                      sharing
                    </li>
                  </ul>
                  <div className={"text-center"}>
                    <span className={classNames("h3")}>Free</span>
                  </div>
                </CardText>
              </CardBody>
            </Card>
            <Card
              className={classNames(
                "my-2",
                "mx-4",
                "thick-shadow",
                "col-md-4",
                "col-sm-6",
                "col-xs-10"
              )}
            >
              <CardBody>
                <CardTitle className={classNames("text-center", "my-3")}>
                  Small Teams
                </CardTitle>
                <CardText>
                  <ul
                    style={{ listStyle: "none" }}
                    className={classNames("my-5", "text-secondary")}
                  >
                    <li>
                      <FiCheckCircle className="text-success" /> Unlimited
                      publishing
                    </li>
                    <li>
                      <FiCheckCircle className="text-success" /> Private mode
                      scrolls
                    </li>
                    <li>
                      <FiXCircle className="text-p-red" /> No document analytics
                    </li>
                    <li>
                      <FiCheckCircle className="text-success" /> One-click
                      sharing
                    </li>
                  </ul>
                  <div className={"text-center"}>
                    <span className={classNames("text-primary")}>
                      <sup className={"h6"}>$</sup>
                      <span className={"h3"}>79</span>
                    </span>
                    <span className={"text-secondary"}>/mo</span>
                  </div>
                </CardText>
              </CardBody>
            </Card>
            <Card
              className={classNames(
                "my-2",
                "mx-4",
                "thin-shadow",
                "col-md-4",
                "col-sm-6",
                "col-xs-10"
              )}
            >
              <CardBody>
                <CardTitle className={classNames("text-center", "my-3")}>
                  Enterprise
                </CardTitle>
                <CardText>
                  <ul
                    style={{ listStyle: "none" }}
                    className={classNames("my-5", "text-secondary")}
                  >
                    <li>
                      <FiCheckCircle className="text-success" /> Unlimited
                      publishing
                    </li>
                    <li>
                      <FiCheckCircle className="text-success" /> Private mode
                      scrolls
                    </li>
                    <li>
                      <FiCheckCircle className="text-success" /> Document
                      analytics
                    </li>
                    <li>
                      <FiCheckCircle className="text-success" /> One-click
                      sharing
                    </li>
                  </ul>
                  <div className={"text-center"}>
                    <span>
                      <sup className={classNames("text-primary", "h6")}>$</sup>
                      <span className={"h3"}>119</span>
                    </span>
                    <span className={"text-secondary"}>/mo</span>
                  </div>
                </CardText>
              </CardBody>
            </Card>
          </Container>
        </Col>
        <Col style={{ textAlign: "center" }} className="my-5">
          <Button
            color="p-red"
            className={classNames("text-uppercase", "px-4", "py-2")}
            onClick={() => {
              scroller.scrollTo("signup", {
                smooth: "easeInOutQuart",
                duration: 500
              });
            }}
          >
            Get started
          </Button>
        </Col>
      </Row>
    );
  }

  _fifthPage() {
    return (
      <Row
        className={classNames(
          "flex-fill",
          "flex-column",
          "align-items-center",
          "align-content-center",
          "justify-contents-center",
          "mb-5"
        )}
        style={{
          minHeight: "70vh",
          position: "relative",
          marginLeft: "0",
          marginRight: "0"
        }}
        id="signup"
      >
        <Col style={{ minHeight: "12vh" }}>
          <img
            src={HexBR}
            alt={""}
            style={{
              position: "absolute",
              top: "-20vh",
              right: 0,
              zIndex: -1,
              minWidth: "150px",
              width: "25vw"
            }}
          />
        </Col>
        <Col>
          <h2 style={{ textAlign: "center" }}>Sign up for early access</h2>
          <p className="subheading" style={{ textAlign: "center" }}>
            PaperGrin is currently not publicly available. Sign up to request
            early access to our private beta version.
          </p>
        </Col>
        <Col className={classNames("col-md-4", "col-9", "text-center")}>
          <Form>
            <FormGroup className={"my-4"}>
              <Label for={"name"} className={"text-secondary"}>
                FULL NAME *
              </Label>
              <Input
                type="text"
                name={"name"}
                id={"name"}
                invalid={Utils.isDataEmpty(this.state.name)}
                onChange={event => {
                  this.onChangeSignUpField("name", event.target.value);
                }}
              />
              {Utils.isDataEmpty(this.state.nameError) ? null : (
                <FormFeedback>{this.state.nameError}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup className={"my-4"}>
              <Label for="email" className={"text-secondary"}>
                EMAIL ADDRESS *
              </Label>
              <Input
                type="email"
                name="email"
                id="email"
                invalid={
                  Utils.isDataEmpty(this.state.email) ||
                  !Regex.EMAIL_VALIDATION_REGEX.test(this.state.email)
                }
                onChange={event => {
                  this.onChangeSignUpField("email", event.target.value);
                }}
              />
              {Utils.isDataEmpty(this.state.emailError) ? null : (
                <FormFeedback>{this.state.emailError}</FormFeedback>
              )}
            </FormGroup>
            <FormGroup className={"my-4"}>
              <Label for={"company"} className={"text-secondary"}>
                COMPANY NAME
              </Label>
              <Input
                type="text"
                name={"company"}
                id={"company"}
                invalid={false}
                onChange={event => {
                  this.onChangeSignUpField("company", event.target.value);
                }}
              />
              {Utils.isDataEmpty(this.state.companyError) ? null : (
                <FormFeedback>{this.state.companyError}</FormFeedback>
              )}
            </FormGroup>
          </Form>
          <Button
            color="p-red"
            className={classNames("text-uppercase", "px-4", "py-2", "mt-4")}
            onClick={this.onSubmit}
          >
            Request Early Access
          </Button>
          <Alert
            color="warning"
            isOpen={this.state.sendingAlertVisible}
            toggle={this.onDismissSendingAlert}
            className={classNames("my-2")}
          >
            Please wait while we add your contact details to our system.
          </Alert>
          <Alert
            color="danger"
            isOpen={this.state.errorAlertVisible}
            toggle={this.onDismissErrorAlert}
            className={classNames("my-2")}
          >
            {this.state.error}
          </Alert>
          <Alert
            color="success"
            isOpen={this.state.successAlertVisible}
            toggle={this.onDismissSuccessAlert}
            className={classNames("my-2")}
          >
            Submitting request. Our team will get in touch with you shortly.
          </Alert>
        </Col>
      </Row>
    );
  }

  render() {
    return (
      <div style={{ overflow: "hidden" }} id="container">
        <Header />
        <div>
          <img
            src={HexTR}
            alt={""}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: -1,
              width: "50vw",
              minWidth: "300px"
            }}
          />
          <Container
            fluid
            className={classNames("flex-fill", "d-flex", "flex-column")}
            style={{ padding: 0 }}
          >
            {HomePage._firstPage()}
            {HomePage._secondPage()}
            {HomePage._thirdPage()}
            {HomePage._fourthPage()}
            {this._fifthPage()}
          </Container>
        </div>
      </div>
    );
  }
}

export default HomePage;
