function isNull(variable) {
  return variable === null || variable === undefined;
}

// check if object or dictionary is empty
// call isDataEmpty vs calling this directly
function isObjectEmpty(object) {
  if (isNull(object)) {
    return true;
  }
  return Object.keys(object).length === 0;
}

// check if object or dictionary is empty
// call isDataEmpty vs calling this directly
function isStringEmpty(str) {
  if (isNull(str)) {
    return true;
  }
  return str.length === 0;
}

// Supports string and json.
// Use this to check
function isDataEmpty(input) {
  if (typeof input === "string") {
    return isStringEmpty(input);
  } else if (typeof input === "boolean") {
    return false;
  } else if (typeof input === "number") {
    return false;
  } else {
    return isObjectEmpty(input);
  }
}

// check if object is function
function isFunction(input) {
  return typeof input === "function";
}

function clamp(x, left, right) {
  return Math.min(Math.max(x, left), right);
}

export default {
  isDataEmpty,
  isFunction,
  isNull,
  clamp
};
