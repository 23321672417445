function ApiGatewayPaths(aws_exports: Object) {
  let endpoints = [];
  let api_gateway_data = aws_exports.aws_cloud_logic_custom;
  api_gateway_data.forEach(api => {
    api.paths.forEach(suffix => {
      endpoints.push({
        name: api.name,
        endpoint: api.endpoint + suffix,
        region: api.region
      }); // push
    }); // paths forEach
  }); // api forEach
  return api_gateway_data;
}

function MobileHubToAmplify(aws_exports: Object) {
  return {
    Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: aws_exports.aws_app_analytics !== "enable",
      // OPTIONAL - Allow recording session events. Default is true.
      autoSessionRecord: true,
      // TODO: Maybe add AWS Pinpoint Endpoint channels like here -
      // https://aws-amplify.github.io/docs/js/analytics#manual-setup
      AWSPinpoint: {
        // OPTIONAL -  Amazon Pinpoint App Client ID
        appId: aws_exports.aws_mobile_analytics_app_id,
        // OPTIONAL -  Amazon service region
        region: aws_exports.aws_mobile_analytics_app_region
      }
    },
    API: {
      endpoints: ApiGatewayPaths(aws_exports)
    }
  };
}

export default {
  MobileHubToAmplify
};
