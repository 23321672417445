const Interpolate = (
  start: Number,
  end: Number,
  steps: Number,
  count: Number
) => {
  return Math.floor(start + ((end - start) * count) / steps);
};

const ToHex = (input: Number) => {
  if (input < 16) {
    return "0" + input.toString(16);
  } else {
    return input.toString(16);
  }
};

export default {
  Interpolate,
  ToHex
};
