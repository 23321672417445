import Amplify from "aws-amplify";
import React from "react";

import "./assets/css/App.css";
import aws_exports from "./aws-exports";
import HomePage from "./pages/HomePage";
import AWSExportsConverter from "./utils/AWSExportsConverter";

Amplify.configure(AWSExportsConverter.MobileHubToAmplify(aws_exports));

function App() {
  return <HomePage />;
}

export default App;
